
<template>
  <div>
    <h1 class="main-header" v-translate>Users</h1>
    <CRow class="mb-3">
      <CCol>
        <label v-translate translate-context="invitations">Global Role ( valid for every visible PicaService )</label>
        <CCard class="no-borders">
          <CCardHeader class="px-2">
            <div class="card-header-actions">
              <CButton block color="primary" v-translate translate-context="list.actions" @click="openInvitationModal()">Invite user</CButton>
            </div>
          </CCardHeader>
          <CCardBody class="px-0">
            <div class="table-responsive custom-table" >
              <CDataTable
                  :hover="false"
                  :bordered="true"
                  :striped="true"
                  :items="invitations.results"
                  :fields="fields"
                  :column-filter="{external: true,lazy:true}"
                  :column-filter-value="filters"
                  :sorter="{external: true, resetable:true}"
                  :loading="loading"
                  :sorter-value="sorting"
                  @update:column-filter-value="updateFilters"
                  @update:sorter-value="updateSorting"
                  class="custom-table custom-table--clean custom-table--has-filters"
              >
                <template #no-items-view>
                  <div class="text-center">
                    <h5 v-translate>No items found</h5>
                  </div>
                </template>
                <template #sorting-icon="{ state, classes }">
                  <div :class="classes">
                    <CIcon name="cipCaretUp" size="lg" v-if="state === 'asc' || state === 0"></CIcon>
                    <CIcon name="cipCaretUp" size="lg" v-else></CIcon>
                  </div>
                </template>
                <template #email="{item}">
                  <td v-if="isStatusValid(item.status)">
                    {{ item.email }}
                  </td>
                </template>
                <template #uploaded-photos="{item}">
                  <translate>{{getStatusLabel(item.status)}}</translate>
                </template>
                <template #actions="{item}">
                  <td>
                    <div class="d-flex justify-content-end mr-2"  v-if="item.status == invitationStatus.PENDING || item.status == invitationStatus.ACCEPTED">
                       <CButton
                          color="primary"
                          variant="outline"
                          class="btn-circle btn-circle--sm mr-2"
                          size="sm"
                          v-c-tooltip="{content: $gettext('Resend invite'), appendToBody: true}"
                          @click="onResend(item.id)"
                          v-if="item.status == invitationStatus.PENDING">
                        <CIcon name="cipSend"/>
                      </CButton>
                      <CButton
                          color="danger"
                          variant="outline"
                          class="btn-circle btn-circle--sm"
                          size="sm"
                          v-c-tooltip="{content: $gettext('Cancel invite'), appendToBody: true}"
                          @click="onDelete(item.id)">
                        <CIcon name="cipTrashFull"/>
                      </CButton>
                    </div>
                  </td>
                </template>
                <template #under-table>
                  <CPagination
                      v-show="invitations.pagination.num_pages > 1"
                      :activePage="invitations.pagination.page"
                      @update:activePage="updatePage"
                      :pages="invitations.pagination.num_pages"
                  />
                </template>
              </CDataTable>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <InviteUserModal ref="invite-user-modal" @sent="onInvitationSent"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {INVITATION_STATUS} from "@/domain/invitations/const";
import InviteUserModal from "@/domain/invitations/components/InviteUserModal.vue";

export default {
  name: 'Invitations',
  components: {InviteUserModal },

  data() {
    return {
      loading: false,
      fields: [
        {key: 'email', label: 'Email'},
        {key: 'role', label: 'Role'},
        {key: 'actions', label: 'Actions', sorter: false, filter: false, _style: 'text-align: right;'},
      ],
      pagination: {
        page_size: 20,
        page: 1,
      },
      sorting: {
        column: null,
        asc: false
      },
      filters: {},
      showEditor: false
    }
  },
  
  async mounted() {
    await this.fetchList()
  },
  computed: {
    ...mapGetters('invitations', [
      'invitations',
    ]),
    invitationStatus(){
      return INVITATION_STATUS
    },
  },
  methods: {
    ...mapActions('invitations', [
      'fetchInvitationList',
      'deleteInvitation',
      'sendInvitation',
      'resendInvitation'
    ]),
    async fetchList() {
      this.loading = true
      await this.fetchInvitationList({
        filters: this.filters,
        pagination: this.pagination,
        sorting: this.sorting
      })
      this.pagination.page = this.invitations.pagination.page
      this.loading = false
    },
    updateFilters(filters) {
      this.filters = filters
      this.pagination.page = 1
      this.fetchList()
    },
    updateSorting(sorting) {
      this.sorting = sorting
      this.fetchList()
    },
    updatePage(page) {
      this.pagination.page = page
      this.fetchList()
    },
    onDelete(id) {
      this.confirm({
        cb_confirm: async () => {
          await this.deleteInvitation(id)
          await this.fetchList()
        }
      })
    },
    isStatusValid(status) {
      return status !== INVITATION_STATUS.CANCELED
    },
    getStatusLabel(status) {
      if(status === INVITATION_STATUS.PENDING){
        return this.$pgettext('invitations.status','Invitation pending')
      }else if(status === INVITATION_STATUS.EXPIRED){
        return this.$pgettext('invitations.status','Invitation expired')
      }else{
        return this.$pgettext('invitations.status','User disabled')
      }
    },
    openInvitationModal() {
      this.$refs['invite-user-modal'].open()
    },
    async onInvitationSent() {
      await this.fetchList()
    },
    async onResend(id) {
      await this.resendInvitation(id)
      await this.notifySuccess(this.$pgettext('invitations.notification', 'Invitation resent'))
    }
  }
}
</script>

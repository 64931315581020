var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{directives:[{name:"translate",rawName:"v-translate"}],staticClass:"main-header"},[_vm._v("Users")]),_c('CRow',{staticClass:"mb-3"},[_c('CCol',[_c('label',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"translate-context":"invitations"}},[_vm._v("Global Role ( valid for every visible PicaService )")]),_c('CCard',{staticClass:"no-borders"},[_c('CCardHeader',{staticClass:"px-2"},[_c('div',{staticClass:"card-header-actions"},[_c('CButton',{directives:[{name:"translate",rawName:"v-translate"}],attrs:{"block":"","color":"primary","translate-context":"list.actions"},on:{"click":function($event){return _vm.openInvitationModal()}}},[_vm._v("Invite user")])],1)]),_c('CCardBody',{staticClass:"px-0"},[_c('div',{staticClass:"table-responsive custom-table"},[_c('CDataTable',{staticClass:"custom-table custom-table--clean custom-table--has-filters",attrs:{"hover":false,"bordered":true,"striped":true,"items":_vm.invitations.results,"fields":_vm.fields,"column-filter":{external: true,lazy:true},"column-filter-value":_vm.filters,"sorter":{external: true, resetable:true},"loading":_vm.loading,"sorter-value":_vm.sorting},on:{"update:column-filter-value":_vm.updateFilters,"update:sorter-value":_vm.updateSorting},scopedSlots:_vm._u([{key:"no-items-view",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('h5',{directives:[{name:"translate",rawName:"v-translate"}]},[_vm._v("No items found")])])]},proxy:true},{key:"sorting-icon",fn:function(ref){
var state = ref.state;
var classes = ref.classes;
return [_c('div',{class:classes},[(state === 'asc' || state === 0)?_c('CIcon',{attrs:{"name":"cipCaretUp","size":"lg"}}):_c('CIcon',{attrs:{"name":"cipCaretUp","size":"lg"}})],1)]}},{key:"email",fn:function(ref){
var item = ref.item;
return [(_vm.isStatusValid(item.status))?_c('td',[_vm._v(" "+_vm._s(item.email)+" ")]):_vm._e()]}},{key:"uploaded-photos",fn:function(ref){
var item = ref.item;
return [_c('translate',[_vm._v(_vm._s(_vm.getStatusLabel(item.status)))])]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.status == _vm.invitationStatus.PENDING || item.status == _vm.invitationStatus.ACCEPTED)?_c('div',{staticClass:"d-flex justify-content-end mr-2"},[(item.status == _vm.invitationStatus.PENDING)?_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$gettext('Resend invite'), appendToBody: true}),expression:"{content: $gettext('Resend invite'), appendToBody: true}"}],staticClass:"btn-circle btn-circle--sm mr-2",attrs:{"color":"primary","variant":"outline","size":"sm"},on:{"click":function($event){return _vm.onResend(item.id)}}},[_c('CIcon',{attrs:{"name":"cipSend"}})],1):_vm._e(),_c('CButton',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:({content: _vm.$gettext('Cancel invite'), appendToBody: true}),expression:"{content: $gettext('Cancel invite'), appendToBody: true}"}],staticClass:"btn-circle btn-circle--sm",attrs:{"color":"danger","variant":"outline","size":"sm"},on:{"click":function($event){return _vm.onDelete(item.id)}}},[_c('CIcon',{attrs:{"name":"cipTrashFull"}})],1)],1):_vm._e()])]}},{key:"under-table",fn:function(){return [_c('CPagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.invitations.pagination.num_pages > 1),expression:"invitations.pagination.num_pages > 1"}],attrs:{"activePage":_vm.invitations.pagination.page,"pages":_vm.invitations.pagination.num_pages},on:{"update:activePage":_vm.updatePage}})]},proxy:true}])})],1)])],1)],1)],1),_c('InviteUserModal',{ref:"invite-user-modal",on:{"sent":_vm.onInvitationSent}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }